import { AuthSettings, useExecuteAuthenticationFlow } from '@features/auth';
import { HomePage, ProjectPage } from '@pages/lazyLoadingPages';
import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { RouteGuard } from './RouteGuard';

type Props = {
    authSettings: AuthSettings;
};

const ApplicationRoutes = ({ authSettings }: Props) => {
    const { isLoadingAuthentication } = useExecuteAuthenticationFlow();

    return (
        <Routes>
            <Route
                element={
                    <Suspense fallback={<div>LOADING</div>}>
                        <RouteGuard scopes={authSettings.scopes} isLoadingAuthentication={isLoadingAuthentication} />
                    </Suspense>
                }
            >
                <Route index element={<HomePage />} />
                <Route element={<ProjectPage origin="create" />} path="/createproject" />
                <Route element={<ProjectPage origin="update" />} path="/updateproject/:projectId" />
                <Route element={<ProjectPage origin="list" />} path="/myprojects" />
            </Route>
        </Routes>
    );
};

export { ApplicationRoutes };
