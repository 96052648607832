import { AuthenticationResult, IPublicClientApplication } from '@azure/msal-browser';
import { agent } from '@shared/api';
import { Me } from './domain';

const executeTokenAdquisition = async (instance: IPublicClientApplication, scopes: string[]) => {
    const account = instance.getActiveAccount() ?? undefined;

    try {
        const response: AuthenticationResult = await instance.acquireTokenSilent({ account, scopes });

        return { account: response.account, accessToken: response.accessToken };
    } catch {
        try {
            await instance.loginRedirect();
        } catch (error) {
            console.warn(`Unable to adquire the token. Reason: ${error}`);
        }

        return null;
    }
};

const getMeInfo = async (signal?: AbortSignal) => {
    try {
        return agent.get<Me>('/api/user/me', signal);
    } catch (error) {
        console.warn(`Unable to get me data. Reason: ${error}`);
        return null;
    }
};

export { executeTokenAdquisition, getMeInfo };
