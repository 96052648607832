import { AuthSettings } from '@features/auth';

type InsightsSettings = {
    key: string;
};

type Settings = {
    appInsights: InsightsSettings;
    auth: AuthSettings;
    apiUrl: string;
};

const getSettings = () => {
    const scopes = import.meta.env.VITE_SCOPES.split(',');

    return {
        appInsights: { key: import.meta.env.VITE_APP_INSIGHTS_KEY || '' },
        auth: {
            authority: import.meta.env.VITE_AUTHORITY || '',
            clientId: import.meta.env.VITE_CLIENT_ID || '',
            postLogoutRedirectUri: import.meta.env.VITE_POST_LOGOUT_REDIRECT_URI || '',
            redirectUri: getRedirectUri() || '',
            scopes: scopes,
        },
        apiUrl: import.meta.env.VITE_API_URL,
    };
};

const getRedirectUri = () => {
    const redirectUris = import.meta.env.VITE_REDIRECT_URIS.split(',');
    return redirectUris.find(f => f === window.location.origin);
};

export { getSettings, type Settings };
