import { produce } from 'immer';
import { StateCreator } from 'zustand';

export type Slice = {
    settings: {
        setApiUrl: (value: string) => void;
        apiUrl: string;
    };
};

export const createSlice: StateCreator<Slice, [], [], Slice> = set => ({
    settings: {
        apiUrl: '',
        setApiUrl: (value: string) =>
            set(
                produce((draft: Slice) => {
                    draft.settings.apiUrl = value;
                })
            ),
    },
});
